import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({ lang, meta, title, description }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          twitterUsername,
        },
      },
    }) => {
      const seo = {
        lang: `en`,
        meta: [], 
        title: title || defaultTitle,
        description: description || defaultDescription
      }
      return (
        <>
          <Helmet 
            htmlAttributes={{lang}} 
            title={seo.title} 
            titleTemplate={titleTemplate}
            meta={[
              {
                name: `description`,
                content: seo.description,
              },
              {
                property: `og:title`,
                content: seo.title,
              },
              {
                property: `og:description`,
                content: seo.description,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: twitterUsername,
              },
              {
                name: `twitter:title`,
                content: seo.title,
              },
              {
                name: `twitter:description`,
                content: seo.description,
              },
            ].concat(meta)}
            />
        </>
      )
    }}
  />
)
export default SEO
SEO.defaultProps = {
  lang: `en`,
  meta: [],
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}
SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        author
        titleTemplate
        defaultDescription: description
        siteUrl: baseUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`