import React from "react"
import Link from "gatsby-link"
import { css } from "@emotion/core";
import { graphql } from "gatsby";
import typography from '../utils/typography'
import Layout from "../components/layout"
import SEO from "../components/SEO"

var rhythm = typography.rhythm

export default ({ data }) => {
  return (
    <Layout>
      <SEO title="JTF starter site blog" description="Please read my blog posts - they're all powered by Gatsby!" />
      <div className="content">
        <h1
          css={css`
            display: inline-block;
          `}
        >
          My recent blog posts
        </h1>
        <h5><span css={css`
                      font-size: 0.85rem; 
                    `}>({data.allMarkdownRemark.totalCount} Posts)<br /><br />
            </span>
        </h5>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id} 
                css={css`
                      padding-bottom: 1rem; 
                    `}
>
            <Link
              to={node.fields.slug}
              css={css`
                text-decoration: none;
                color: inherit; 
                &:hover {
                  color: hotpink;
                  text-decoration: none;
                }
              `}
            >
              <h5
                css={css`
                  margin-bottom: ${rhythm(1 / 4)};
                `}
              >
                {node.frontmatter.title}
              </h5>
              <p>{node.excerpt}<br />
              <span css={css`
                          font-size: 0.7rem;
                        `}>
                        {"Posted on: "}{node.frontmatter.date}
              </span>
              </p>
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark( filter: { frontmatter: {template: { eq: "blog" }}}, , sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`